import {qspost} from '@/plugins/axios'

/*
 * 登录
 * @constructor jack
 * */
export const login = (userName, userPass) => qspost('/index/Common/adminLogin', {userName, userPass})

/*
 * 获取管理的各公司
 * @constructor jack
 * */
export const orgList = () => qspost('/index/Common/adminLogin', {})
export const isAgreementShow = () => qspost('/index/Org/isAgreementShow', {})


