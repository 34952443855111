<template>
  <div class="login-wrapper">
    <div class="login-box">
      <h3 class="login-logo">善远HR管理系统</h3>
      <div class="login-form">
        <h3>登录</h3>
        <div class="form-item">
          <div class="form-icon">
            <img src="../../assets/img/account.png" alt="">
          </div>
          <input type="text" placeholder="请输入用户名" v-model="account">
        </div>
        <div class="form-item">
          <div class="form-icon">
            <img src="../../assets/img/pass.png" alt="">
          </div>
          <input type="password" placeholder="请输入密码" v-model="pass">
        </div>
        <div class="form-item">
          <drag-verify
              ref="dragVerify"
              :width="405"
              :height="48"
              :circle="false"
              :textSize="'14px'"
              completedBg="#368ad1"
              progressBarBg="#368ad1"

              :isPassing.sync="isPassing"
              text="请按住滑块拖动"
              successText="验证通过"
              handlerIcon="el-icon-d-arrow-right"
              successIcon="el-icon-circle-check"
              @passcallback="passcallback"
          >
          </drag-verify>
        </div>
        <div class="login-btn" @click="loginSubmit">立即登录</div>
      </div>
    </div>

    <el-dialog
        title="善远HR用户协议"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="tips"
        width="1280px;"
    >
      <div class="tips-box">
        <h2>用户协议</h2>

        <h3>一、引言</h3>

        <p>本用户协议（“协议”）是您与北京善远管理咨询有限公司（以下简称“我们”或“本公司”）之间就您使用我们提供的善远HR
          SaaS系统（以下简称“系统”）所达成的法律协议。本协议包括以下条款和条件：</p>

        <h3>二、定义</h3>

        <p>“善远HR SaaS系统”是指我们通过互联网提供的系统服务，包括但不限于人力资源管理中的人员信息管理和入离职管理等。</p>
        <p>“用户”或“您”是指通过我们的网站使用我们的善远HR SaaS系统的任何个人或实体。</p>

        <h3>三、协议条款</h3>

        <p>1. 许可：我们授予您非独家、非转让、不可分许可的权利使用我们的HR
          SaaS系统。对于付费用户，我们还将授予额外的使用权限和功能。</p>
        <p>2. 内容：您同意不发布或上传任何违反法律、侵犯他人权利或包含恶意代码的内容。</p>
        <p>3. 隐私：我们尊重用户的隐私权。您同意我们按照我们的隐私政策处理您的个人信息。</p>
        <p>4. 知识产权：我们保留对本HR
          SaaS系统的所有知识产权。您不得复制、修改、翻译、汇编、反向工程、反汇编或以其他方式获取系统的源代码。</p>
        <p>5.
          数据安全：我们将采取合理的安全措施来保护您的数据安全，但互联网传输中的数据可能会被拦截或泄露，因此我们不保证数据的绝对安全。</p>
        <p>6. 责任限制：在任何情况下，我们对因使用本HR
          SaaS系统引起的任何损失、损害或费用承担的总责任不超过您支付的费用。</p>
        <p>7. 更新和修改：我们保留随时更新或修改本HR
          SaaS系统的权力，但我们会尽合理努力在更改前通知您。您继续使用系统即表示您接受这些更改。</p>
        <p>8. 终止：如果我们终止本协议或您的使用权限，您应立即停止使用系统。</p>
        <p>9. 法律适用：本协议受中国法律的管辖，且该法律适用于您使用系统的全过程。</p>
        <p>10.
          争议解决：如果因本协议产生争议，双方应首先通过友好协商解决。如果争议无法解决，应提交给有管辖权的法院诉讼解决。</p>
        <p>11. 其他：如果本协议的任何条款被视为无效或不可执行，则该无效或不可执行不应影响其他条款的有效性或可执行性。</p>
        <p>12.
          生效日期和修订日期：本用户协议自用户同意并开始使用本系统时生效，并在用户与本公司之间的使用关系持续期间保持有效。用户可以通过点击确认接受本用户协议或以其他方式明确表示接受本用户协议，而无需另外通知。本公司保留随时修改本用户协议的权利，并且此类修改自修改公布之日立即生效。用户应在每次使用本系统前仔细阅读修改后的用户协议。如果用户不同意此类修改，则应立即停止使用本系统。</p>
        <p>13. 解释权：本用户协议的解释权归本公司所有。</p>
        <p>14.
          整体协议：本用户协议构成用户与本公司之间就使用本系统事宜的全部协议，并取代以前的所有口头或书面协议，除非这些协议与本用户协议相冲突。本协议未涵盖的内容以及与本协议有关的任何问题或争议应由双方协商解决。</p>

        <h3>四、补充说明</h3>
        <p>1. 免费使用：我们提供的HR
          SaaS系统原则上供用户免费使用。然而，为了提供更优质的服务，我们可能会对部分功能或高级功能收费。</p>
        <p>2. 数据备份：我们强烈建议您定期备份您的数据，以防因任何原因导致数据丢失。</p>
        <p>3. 系统更新：为了提供更好的用户体验和功能，我们会定期更新我们的HR
          SaaS系统。更新后的系统可能包含新的功能、修复已知问题或改进性能。</p>
        <p>4. 用户反馈：我们欢迎用户提供反馈和建议，以帮助我们改进我们的HR SaaS系统。</p>
        <h3>五、用户责任</h3>
        <p>1. 遵守法律：您在使用我们的HR
          SaaS系统时应遵守所有适用的法律和法规。这包括但不限于隐私法、版权法和其他知识产权法。</p>
        <p>2. 不得滥用：您不得使用我们的HR
          SaaS系统进行任何非法活动，包括但不限于侵犯他人隐私、传播恶意系统或进行未经授权的广告活动。</p>
        <p>3. 保护密码：您应对您的账户密码保密，并采取适当的安全措施来保护您的账户安全。</p>
        <h3>六、一般条款</h3>
        <p>1. 本协议可在任何时候由我们单方面修改。修改后的协议将在修改公布之日立即生效。</p>
        <p>2. 一旦您开始使用我们的HR SaaS系统，即表示您已接受并同意本协议的所有条款和条件。</p>
        <p>3. 本协议的解释权归我们所有。</p>
        <p>4.
          如果本协议中的任何条款或条件被视为无效或不可执行，则该无效或不可执行不应影响其他条款和条件的效力或可执行性。</p>
        <p>5. 本协议受我们所在地的法律管辖。</p>
        <p>6.
          本协议构成用户与我们之间就使用本系统事宜的全部协议，并取代以前的所有口头或书面协议，除非这些协议与本协议相冲突。本协议未涵盖的内容以及与本协议有关的任何问题或争议应由双方协商解决。</p>
        <p>7.
          本协议自用户开始使用本系统时生效，并在用户与本公司之间的使用关系持续期间保持有效。用户可以通过点击确认接受本协议或以其他方式明确表示接受本协议，而无需另外通知。本公司保留随时修改本协议的权利，并且此类修改自修改公布之日立即生效。用户应在每次使用本系统前仔细阅读修改后的协议。如果用户不同意此类修改，则应立即停止使用本系统。</p>

        <h3>七、争议解决</h3>

        <p>1.
          如果因本协议引起的争议，双方应首先通过友好协商解决。协商不成的，任何一方可将争议提交至有管辖权的法院诉讼解决。</p>
        <p>2. 在争议解决期间，除涉及争议的部分外，双方应继续履行本协议的其他条款和义务。</p>

        <h3>八、其他条款</h3>

        <p>1. 如果您对我们的HR SaaS系统有任何问题或建议，请随时联系我们的客户支持团队。</p>
        <p>2. 我们保留在任何时候对本协议进行修改的权利，并会在修改公布后立即生效。</p>

        <h3>九、终止</h3>
        <p>1. 如果我们终止本协议或您的使用权限，您应立即停止使用我们的HR SaaS系统，并删除所有系统的副本。</p>
        <p>2. 在任何情况下，我们对因使用我们的HR SaaS系统引起的任何损失、损害或费用承担的总责任不超过您支付的费用。</p>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button type="primary" @click="IKonw">我已阅读并同意</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dragVerify from 'vue-drag-verify2'
import {isAgreementShow, login} from "@/api/login";

export default {
  name: "login",
  data() {
    return {
      account: 'syhrAdmin',
      // account: '',
      pass: 'syhrAdmin***.',
      // pass: '',
      isPassing: false,
      tips: false,
      role_id: '',

    }
  },
  created() {
  },
  components: {
    dragVerify
  },
  methods: {
    passcallback() {
      this.isPassing = true
    },
    IKonw(){
      if (this.role_id === 1) {
        this.$router.replace('/admin/orgList')
        this.role_id =''

      }
      if (this.role_id === 2) {
        this.$router.replace('/roleList')
        this.role_id =''
      }
    },
    async loginSubmit() {
      if (!this.account) return this.$message.error('请输入用户名')
      if (!this.pass) return this.$message.error('请输入密码')
      if (!this.isPassing) return this.$message.error('请滑动验证')
      const loading = this.$loading({
        lock: true,
        text: '登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await login(this.account, this.$md5(this.pass))

      if (_data.status === 200) {
        this.$store.state.token = _data.data.token
        this.$store.state.user = _data.data.data
        this.$store.state.isAdmin =  _data.data.data.role_id === 1
        localStorage.setItem('isAdmin', JSON.stringify(_data.data.data.role_id === 1))
        this.role_id = _data.data.data.role_id
        setTimeout(async () => {
          let _state = await isAgreementShow()
          if (_state.data === 1) {
            // 显示弹框
            this.tips = true
          } else {
            // 不显示弹框
            if (_data.data.data.role_id === 1) {
              setTimeout(() => {
                loading.close();
                this.$router.replace('/admin/orgList')
                this.role_id =''
              }, 500)

            }
            if (_data.data.data.role_id === 2) {
              setTimeout(() => {
                loading.close();
                this.$router.replace('/roleList')
                this.role_id =''
              }, 500)
            }
          }
        }, 500)



      } else {
        loading.close();
        this.isPassing = false
        this.$refs.dragVerify.reset();
      }


    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.login-wrapper {
  width: 100%;
  height: 100vh;
  background: url(../../assets/img/login_bg.png) #f5f5f5 no-repeat top center;
  background-size: 100% auto;

  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .login-logo {
      display: block;
      font-size: 48px;
      text-align: center;
      color: #fff;
      margin: 30px auto;
    }

    .login-form {
      width: 958px;
      height: 549px;
      padding: 80px 100px 0 430px;
      background: url(../../assets/img/login_form.png) no-repeat center;

      h3 {
        font-size: 24px;
        line-height: 38px;
        letter-spacing: 2px;
        color: #368ad1;
        text-align: center;
        margin-bottom: 30px;
      }

      .form-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 405px;
        height: 50px;
        margin: 0 auto 20px;
        border-radius: 4px;
        border: solid 1px #e6e6e6;
        overflow: hidden;

        .form-icon {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 46px;
          height: 46px;

          img {
            width: 16px;
          }
        }

        input {
          flex: 1;
          display: block;
          font-size: 16px;
          height: 46px;
          background: transparent;
        }

        .form-btn {
          padding: 0 10px;
          color: #009688;
        }

        .form-yzm {
          display: block;
          width: 120px;
          height: 46px;
        }
      }

      .login-btn {
        display: block;
        width: 425px;
        height: 56px;
        margin: 0 auto;
        text-align: center;
        line-height: 54px;
        font-size: 18px;
        color: #fff;
        background-color: #368ad1;
        border-radius: 6px;
        border: solid 1px #eeeeee;
        cursor: pointer;
      }
    }
  }

  .tips-box {
    height: 50vh;
    overflow-y: auto;
    h2{
      text-align: center;
      line-height: 200%;
      font-size: 28px;
    }
    h3{
      line-height: 200%;
      margin: 10px 0;
      color:  #368ad1;
    }
    p{
      line-height: 150%;
      text-indent: 28px;
    }
  }
}
</style>
